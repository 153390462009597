@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/constants";
@import "styles/neumorphism";

body {
	background-color: $bg-color;
	color: $text-color;
}
