@import "constants";

@mixin neumorphic($x, $y, $blur) {
  box-shadow:
    #{$x}px #{$y}px #{$blur}px $neumorphic-lower-shadow,
    #{-$x}px #{-$y}px #{$blur}px $neumorphic-upper-shadow;
}

@mixin neumorphic_inset($x, $y, $blur) {
  box-shadow:
    inset #{$x}px #{$y}px #{$blur}px $neumorphic-lower-shadow,
    inset #{-$x}px #{-$y}px #{$blur}px $neumorphic-upper-shadow;
}

.neu-up {
  @include neumorphic(6, 6, 8);
  border-radius: 8px;
}

.neu-down {
  @include neumorphic_inset(6, 6, 8);
  border-radius: 8px;
}

button {
  @include neumorphic(6, 6, 8);
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 6px 12px;

  &:disabled {
    box-shadow: none;
  }

  &:active:not(:disabled) {
    @include neumorphic_inset(6, 6, 8);
  }

  &.fab {
    border-radius: 50%;
    padding: 12px;
  }
}

input {
  @include neumorphic_inset(6, 6, 8);
  padding: 2px 8px;
  background-color: transparent;
  border-radius: 4px;
  width: 64px;
  text-align: center;

  &::-webkit-inner-spin-button {
    display: none;
  }
}
